import React from "react";
import {graphql, Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image";
import styled, { css } from 'styled-components';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import Layout from "../components/layout"
import Seo from "../components/seo"
import LangSelector from "../components/LangSelector";
import img01Src from '../images/foto-04.jpg';
import img02Src from '../images/foto-05.jpg';
import { APARTMENT_SELECTION } from "../routes";
import { TopPanel, Title } from "../components/common/topPanel";
import ContentWrapper from '../components/common/contentWrapper';
import Map from "../components/Map";
import Gallery from "../components/Gallery";
import bgShadowSrc from '../images/bg-shadow.jpg';

const Content = styled.div`
`;

const Subtitle = styled.p`
  font-family: 'Playfair Display', Georgia, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: #303030;
  margin-bottom: 1.5rem;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    width: 60px;
    height: 5px;
    background-color: #ff940a;
    transform: translate(-50%,-50%);
  }

  @media(min-width: 768px) {
    font-size: 1.875rem;
    line-height: 3rem;
  }
`;

const LeftSubtitle = styled(Subtitle)`
  text-align: left;

  &:before {
    left: 0;
    transform: none;
  }
`;

const CenteredText = styled.div`
  text-align: center;
  color: #58595b;

  p {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    line-height: 1.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;

const LeftText = styled(CenteredText)`
  text-align: left;
`;

const Section = styled.div`
  background-color: #fff;
  background-image: url(${bgShadowSrc});
  background-repeat: repeat-y;
  background-position: top left;
  padding: 70px 1.5rem;

  img {
    width: 100%;
    height: auto;
  }

  @media(min-width: 768px) {
    padding: 70px;
  }
`;

const SpecialSection = styled(Section)`
  padding: 70px 1.5rem 50px;

  ${Subtitle} {
    text-align: left;

    &:before {
      left: 0;
      transform: none;
    }
  }


  ${CenteredText} {
    text-align: left;
  }

  @media(min-width: 768px) {
    padding: 70px 70px 50px;

    ${Subtitle} {
      text-align: center;

      &:before {
        content: '';
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }

    ${CenteredText} {
      text-align: center;
    }
  }
`;

const DarkSection = styled.div`
  background-color: #313131;
  padding: 70px 1.5rem;

  ${Subtitle} {
    color: #ffffff;
    margin-top: 1.5rem;
  }

  ${CenteredText} {
    color: #ffffff;
  }

  @media(min-width: 768px) {
    padding: 70px;
  }

  @media(min-width: 1400px) {

    ${Subtitle} {
      margin-top: 0;
    }
  }

`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 6.25rem;
  row-gap: 2rem;

  @media(min-width: 1400px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Img = styled.img`
  max-width: ${({maxWidth}) => maxWidth || 'none'};
  width: 100%;
  height: auto;

  ${({maxWidth}) => maxWidth && css`
    margin-left: auto;
    margin-right: auto;
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  a {
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    background-color: #303030;
    padding: 1rem 3rem;
  }

  a:hover {
    color: #fff;
    text-decoration: none;
    background-color: #ff940a;
  }
`;

function AboutProjectPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Seo title={t('seo.about_project.title')} />
      <Content>
        <TopPanel>
          <StaticImage src="../images/img-panel-02.jpg" className="panel-image" placeholder="blurred" alt={t('photo_alt')} />
          <LangSelector />
          <Title as="h1">
            <Trans i18nKey="about_project.title">
              message
            </Trans>
          </Title>
        </TopPanel>
        <Section>
          <ContentWrapper>
            <Grid>
              <div>
                <LeftSubtitle as="h2">{t('about_project.block_1.title')}</LeftSubtitle>
                <LeftText>
                  <p>{t('about_project.block_1.line_1')}</p>

                  <p>
                    <Trans i18nKey="about_project.block_1.line_2">
                      message <strong>message</strong> message
                    </Trans>
                  </p>
                  <p>{t('about_project.block_1.line_3')}</p>
                </LeftText>
              </div>
              <Img src={img01Src} maxWidth="600px" alt={t('photo_alt')} />
            </Grid>
          </ContentWrapper>
        </Section>
        <DarkSection>
          <ContentWrapper>
            <Grid>
              <Img src={img02Src} maxWidth="600px" alt={t('photo_alt')} />
              <div>
                <LeftSubtitle as="h2">{t('about_project.block_2.title')}</LeftSubtitle>
                <LeftText>
                  <p>
                    <Trans i18nKey="about_project.block_2.line_1">
                      message <strong>message</strong> message
                    </Trans>
                  </p>
                  <p>
                    <Trans i18nKey="about_project.block_2.line_2">
                      message <strong>message</strong> message
                    </Trans>
                  </p>
                  <p>{t('about_project.block_2.line_3')}</p>
                </LeftText>
              </div>
            </Grid>
          </ContentWrapper>
        </DarkSection>
        <SpecialSection>
          <ContentWrapper>
            <Subtitle as="h2">{t('about_project.block_3.title')}</Subtitle>
            <CenteredText>
              <p>
                <Trans i18nKey="about_project.block_3.line_1">
                  message <strong>message</strong> message
                </Trans>
              </p>
              <p>
                <Trans i18nKey="about_project.block_3.line_2">
                  message <strong>message</strong> message
                </Trans>
              </p>
              <p>{t('about_project.block_3.line_3')}</p>
            </CenteredText>
          </ContentWrapper>
          <Gallery />
          <ButtonWrapper>
            <Link to={APARTMENT_SELECTION}>{t('select_apartment')}</Link>
          </ButtonWrapper>
        </SpecialSection>
        <Map />
      </Content>
    </Layout>
  )
}

export default AboutProjectPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
